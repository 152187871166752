import {Inject, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ContactInfo } from '@app/cms-components/contact-form/ContactInfo';
import { Constants } from '../Constants';
import {DOCUMENT} from "@angular/common";

export class AddressValidtionResponse {
    addressComplete: boolean;
    payloadMatchesRecord: boolean;
    address: ValidationAddress;
}

export class ValidationAddress {
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    zip5: string;

    constructor(
        a1: string,
        a2: string,
        city: string,
        state: string,
        country: string,
        zip5: string
    ) {
        this.address1 = a1;
        this.address2 = a2;
        this.city = city;
        this.state = state;
        this.country = country;
        this.zip5 = zip5;
    }
}

@Injectable({
    providedIn: 'root'
})
export class SharedUtilsService {
    monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];

    monthFullNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    dayFullNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    openTill;

    opensAt;
    rootUrl = '';

    constructor(
      private httpClient: HttpClient,
      @Inject(DOCUMENT) private document: Document) {
        this.rootUrl = environment.siteDNSName;
    }

    formatHour(d: Date) {
        const hh = d.getHours();
        const m = d.getMinutes();

        return this.formatHourWithMinutes(hh, m);
    }

    convert24TimeStringTo12HourTimeString(milTimeString: string): string {
        // eslint-disable-next-line prefer-const
        let [hours, minutes] = milTimeString.split(':').map(Number);

        if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
            throw new Error('Invalid time string');
        }

        const period = hours < 12 ? 'AM' : 'PM';

        if (hours === 0) {
            hours = 12;
        } else if (hours > 12) {
            hours = hours - 12;
        }
        const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

        return `${formattedHours}:${formattedMinutes} ${period}`;
    }

    formatHourWithMinutes(hh: any, m: any) {
        let dd = 'am';
        let h = hh;
        if (h >= 12) {
            h = hh - 12;
            dd = 'pm';
        }
        if (h === 0) {
            h = 12;
        }

        m = m < 10 ? '0' + m : '' + m;

        return parseInt(h, 10) + ':' + m.slice(-2) + dd;
    }

    storeOpeningStatus(storeHours, storeTimezone) {
        const date = new Date(this.getStoreCurrentTime(storeTimezone));
        const n = date.getDay();
        const h = date.getHours();
        const m = date.getMinutes();
        const now = (h < 10 ? '0' + h : '' + h) + '' + (m < 10 ? '0' + m : '' + m + '');
        const weekdays = [
            ['Sunday', storeHours.sundayOpeningHour, storeHours.sundayClosingHour],
            ['Monday', storeHours.mondayOpeningHour, storeHours.mondayClosingHour],
            ['Tuesday', storeHours.tuesdayOpeningHour, storeHours.tuesdayClosingHour],
            ['Wednesday', storeHours.wednesdayOpeningHour, storeHours.wednesdayClosingHour],
            ['Thursday', storeHours.thursdayOpeningHour, storeHours.thursdayClosingHour],
            ['Friday', storeHours.fridayOpeningHour, storeHours.fridayClosingHour],
            ['Saturday', storeHours.saturdayOpeningHour, storeHours.saturdayClosingHour]
        ];
        const day = weekdays[n];
        const nextDay = n === 6 ? weekdays[0] : weekdays[n + 1];

        this.openTill = this.formatHourWithMinutes(day[2].substr(0, 2), day[2].substr(2));
        this.opensAt = this.formatHourWithMinutes(day[1].substr(0, 2), day[1].substr(2));

        if (parseInt(now, 10) > parseInt(day[1], 10) && parseInt(now, 10) < parseInt(day[2], 10)) {
            return (
                'Open Now until <b>' +
                this.formatHourWithMinutes(day[2].substr(0, 2), day[2].substr(2)) +
                '</b>'
            );
        } else if (
            parseInt(now, 10) > parseInt(day[1], 10) &&
            parseInt(now, 10) > parseInt(day[2], 10)
        ) {
            this.opensAt = this.formatHourWithMinutes(
                nextDay[1].substr(0, 2),
                nextDay[1].substr(2)
            );

            return (
                'Closed Now - Opens at <b>' +
                this.formatHourWithMinutes(nextDay[1].substr(0, 2), nextDay[1].substr(2)) +
                '</b>'
            );
        } else {
            return (
                'Closed Now - Opens at <b>' +
                this.formatHourWithMinutes(day[1].substr(0, 2), day[1].substr(2)) +
                '</b>'
            );
        }
    }

    storeOpeningTill(storeHours, storeTimezone) {
        return this.openTill;
    }

    storeOpeningAt(storeHours, storeTimezone) {
        return this.opensAt;
    }

    getStoreCurrentTime(timeZone) {
        const options = {
            timeZone,
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        };
        const formatter = new Intl.DateTimeFormat([], {
            timeZone,
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });

        return formatter.format(new Date());
    }

    scrollTo(elementId?: string) {
      if (elementId) {
        const element = this.document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
    }

    uploadPhotoFile(file: any, filepath: string, fileName?: string) {
        const params = new FormData();

        params.append('filepath', filepath);
        if (fileName) {
            params.append('file', file, fileName);
        } else {
            params.append('file', file);
        }

        const headers = new HttpHeaders({ 'api-key': environment.imageUploadKey });

        return this.httpClient.post(this.rootUrl + `/api/small-business-registry/fileupload/image`, params, { headers });
    }

    deletePhotoFile(filepath: string) {
        const headers = new HttpHeaders({ 'api-key': environment.imageUploadKey });
        let params = new HttpParams();
        params = params.append('assetType', 'image');
        params = params.append('file', filepath);

        return this.httpClient.delete(this.rootUrl + '/api/small-business-registry/fileupload/private/media', {
            headers,
            params
        });
    }

    validateAddress(address: ValidationAddress) {
        return this.httpClient.post(this.rootUrl + `/api/utility/address`, address);
    }

    isNullOrUndefined(x: any): boolean {
        return x === null || x === undefined || (this.isString(x) && x.length === 0);
    }

    isString(x: any): x is string {
        return typeof x === 'string';
    }

    postContactInformation(contactInfo: ContactInfo, contactFormType: string) {
        return this.httpClient.post(
            this.rootUrl + `/api/utility/contact/${contactFormType}`,
            contactInfo
        );
    }

    getStoreTrendingProducts(storeNumber) {
        return this.httpClient.get(
            this.rootUrl + '/api/utility/product/trendingProducts/store/' + storeNumber
        );
    }

    getFullStateName(stateShortName: string) {
        const states = Constants.STATES;
        for (const st of states) {
            if (st.shortName === stateShortName.toUpperCase()) {
                return st.fullName;
            }
        }
    }
}
