export class IconMappings {
    static mapping = {
        'play-icon': '/assets/icon-play.svg',
        'icon-down-arrow': '/assets/icon-down-arrow.svg',
        'play-stroked-icon': '/assets/play_circle_outline-24px.svg',
        'icon-down-arrow-hover': '/assets/icon-down-arrow-hover.svg',
        'calendar-icon': '/assets/calendar-icon.svg',
        'icon-location-hover': '/assets/icon-location-hover.svg',
        'icon-chevron': '/assets/icon-chevron.svg',
        'new-icon-chevron': '/assets/new-icon-chevron.svg',
        'icon-right-arrow': '/assets/icon-right-arrow.svg',
        'icon-right-arrow-white': '/assets/icon-right-arrow-white.svg',
        'icon-right-arrow-blue': '/assets/icon-right-arrow-blue.svg',
        'icon-right-arrow-hover': '/assets/icon-right-arrow-hover.svg',
        logo: '/assets/logo.svg',
        'like-button': '/assets/social/like.svg',
        'like-button-hover': '/assets/social/like-hover.svg',
        'active-like-button': '/assets/social/active-like-button.svg',
        'icon-twitter': '/assets/social/twitter.svg',
        'twitter-hover': '/assets/social/twitter-hover.svg',
        'active-twitter-icon': '/assets/social/active-twitter-icon.svg',
        'facebook-icon-active': '/assets/social/facebook-icon-active.svg',
        'icon-email': '/assets/social/icon-email.svg',
        'icon-printer': '/assets/social/icon-printer.svg',
        'active-icon-printer': '/assets/social/active-icon-printer.svg',
        'icon-download': '/assets/social/icon-download.svg',
        'icon-download-hover': '/assets/social/icon-download-hover.svg',
        'active-download-icon': '/assets/social/active-download-icon.svg',
        'icon-hamburger': '/assets/icon-hamburger.svg',
        'icon-staples-store-location': '/assets/icon-staples-store-location.svg',
        'search-icon': '/assets/search-icon.svg',
        'logo-mobile': '/assets/logo-mobile.svg',
        pinterest: '/assets/pinterest.svg',
        ig: '/assets/social/instagram-icon.svg',
        'ig-hover': '/assets/instagram-icon.svg',
        'facebook-icon-blue': '/assets/facebook-icon-blue.svg',
        'facebook-icon-blue-active': '/assets/facebook-icon-blue-active.svg',
        'twitter-old': '/assets/twitter-old.svg',
        'staples-logo': '/assets/staples-logo.svg',
        'previous-button': '/assets/previous-button.svg',
        'next-button': '/assets/next-button.svg',
        'location-icon': '/assets/icon-location.svg',
        'reply-icon': '/assets/reply-icon.svg',
        'long-logo': '/assets/long-logo.svg',
        'close-icon': '/assets/close-icon.svg',
        'close-icon-simple': '/assets/close-simple-line-icons.svg',
        'shopping-bag': '/assets/shopping-bag.svg',
        'account-icon': '/assets/account-icon.svg',
        'staples-rewards-logo': '/assets/staples-rewards-logo.svg',
        'left-arrow': '/assets/left-arrow.svg',
        'caution-sign': '/assets/caution-sign.svg',
        'icon-play-outline': '/assets/icon-play-outline.svg',
        'icon-shopnow': '/assets/icon-shopnow.svg',
        'icon-staples': '/assets/icon-staples.svg',
        'icon-shopnow-white': '/assets/icon-shopnow-white.svg',
        'icon-shop': '/assets/icon-shop.svg',
        'icon-store': '/assets/icon-store.svg',
        mobile: '/assets/mobile.svg',
        mobile2: '/assets/mobile2.svg',
        facebook: '/assets/facebook-light-grey.svg',
        twitter: '/assets/social/twitter-x.svg',
        linkedin: '/assets/linkedin-light-grey.svg',
        instagram: '/assets/instagram-light-grey.svg',
        tiktok: '/assets/tiktok-light-grey.svg',
        'thin-blue-arrow': '/assets/thin-blue-arrow.svg'
    };
}
